import React, { useState, useEffect } from 'react'
import ReactPlayer from 'react-player'
// import LoaderSvg from 'images/ui/loader.svg'

export default function VideoBlock({ content }) {
  const [videoUrl, setVideoUrl] = useState('')
  const { optimizedVideos } = content || {}

  const getVideoUrl = () => {
    let getVideoUrl = ''
    if (typeof window !== 'undefined') {
      // get the screen width pixels
      const screenRatio = window.devicePixelRatio || 1
      const screenWidth = window.innerWidth * screenRatio

      // get the nearest matching screen width
      const videoWidths = [1920, 1280, 960, 640]
      let widthMatch = ''
      videoWidths.forEach((thisWidth) => {
        if (thisWidth > screenWidth) widthMatch = thisWidth // find the nearest match which is greater than the current screen width
      })

      // get the video that matches the matching screen width
      const videoMatch = optimizedVideos?.find((video) => {
        return video.localFile.url.includes(widthMatch + 'w')
      })
      getVideoUrl = videoMatch?.localFile.url
    }
    setVideoUrl(getVideoUrl)
  }

  useEffect(() => {
    getVideoUrl()
    // window.addEventListener('resize', getVideoUrl)
    // return () => window.removeEventListener('resize', getVideoUrl)
  }, [])

  return (
    <section className='video-block'>
      {videoUrl && (
        <>
          {/* <div className='loader'>
            <LoaderSvg />
          </div> */}
          <ReactPlayer
            url={videoUrl}
            className='react-player'
            loop
            playing
            muted
            playsinline
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload noplaybackrate',
                  disablePictureInPicture: true,
                },
              },
            }}
            controls
            width='100%'
            height='100%'
          />
        </>
      )}
    </section>
  )
}
